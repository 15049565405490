import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Layout from '../components/layout';
import PostList from '../components/post-list';
import styled from 'styled-components';
import StyledLink from '../components/styled-link';
import { Helmet } from 'react-helmet';

const HomePage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;
  const intro = data.markdownRemark.html;
  const title = data.markdownRemark.frontmatter.title;

  return (
    <Layout title={title}>
      <Helmet>
        <script defer src={withPrefix('gradient.js')} />
      </Helmet>
      <Intro
        className='no-touch'
        dangerouslySetInnerHTML={{
          __html: intro,
        }}
      />
      <canvas role="button" tabIndex="0" className='rotating disable-select' id="gradient-canvas">
      </canvas>
    </Layout>
  );
};

export default HomePage;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 440px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 14rem;
  margin-bottom: var(--size-900);
  text-align: center;
  pointer-events: none;
  @media (max-width: 450px) {
    max-width: 250px;
    margin-top: 7.5rem;
  }
  
  & h1 {
  color: rgba(255, 255, 255, 0.1);
  -webkit-text-stroke: 1px white;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  }

  & p {
    text-transform: capitalize;
    font-size: var(--size-400);
    color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
  }

  @media screen and (max-width: 700px) {
    & h1 {
      font-size: var(--size-700);
    }
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 9
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          title
          tags
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
